<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col cols="12">
          <divisoria-descricao
            :titulo="'Grupo Geral'"
            :descricao="'Informações padrões sobre o cliente'"
            espaco-extra
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Razão Social"
            label-for="razaoSocialInput"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Razao social"
            >
              <b-input
                id="razaoSocialInput"
                v-model="value.razaoSocial"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Nome fantasia"
            label-for="nomeFantasiaInput"
          >
            <b-input
              id="nomeFantasiaInput"
              v-model="value.nomeFantasia"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <b-form-group
            label-for="tipoInscricaoInput"
            label="Tipo inscrição"
          >
            <b-select
              id="tipoInscricaoInput"
              ref="tipoInscricao"
              v-model="value.cadastroFederalTipo"
              :options="[{text:'CNPJ', value:'cnpj'},{value:'cpf',text:'CPF'}]"
              @change="value.cadastroFederal = ''"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group>
            <cadastro-federal-input
              ref="cadastroFederal"
              v-model="value.cadastroFederal"
              class="mb-50"
              :buscar-dados="obterDados"
              :is-cnpj="value.cadastroFederalTipo === 'cnpj'"
              :validacao-blur="cnpjExistente"
              @dados-empresa="enviarDadosPaiCnpj($event.data)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <certificado-select v-model="value.certificadoDigital" />
          <b-form-checkbox
            id="responsavelLegal"
            v-model="value.responsavelLegal"
            type="checkbox"
            style="top: -10px;"
          >Responsável Legal</b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <b-form-group
            label="Regime"
            label-for="regimeSelect"
          >
            <b-select
              id="regimeSelect"
              v-model="value.empresaRegime"
              :options="[
                {text: 'Não informado', value: 'none'},
                {text: 'Simples nacional', value: 'simplesnacional'},
                {text: 'MEI', value: 'mei'} ,
                {text:'Normal', value: 'normal'}]"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Natureza jurídica"
            label-for="naturezaJuridica"
          >
            <b-input
              id="naturezaJuridica"
              v-model="value.naturezaJuridica"
            />
          </b-form-group></b-col>
        <b-col cols="3">
          <b-form-group
            label="Inscrição estadual"
            label-for="inscricaoUFInput"
          >
            <b-input
              id="Inscrição estadual"
              v-model="value.inscricaoEstadual"
              :disabled="isentoEstadual"
              :type="isentoEstadual ? '' : 'number'"
            />
            <b-form-checkbox
              id="isentoEstadual"
              v-model="isentoEstadual"
              type="checkbox"
              class="mt-50 ml-80"
              text-field="ISENTO"
              @change="isentoEstadual ? value.inscricaoEstadual = 'ISENTO' : value.inscricaoEstadual = ''"
            >ISENTO</b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Inscrição municipal"
            label-for="inscricaoMunInput"
          >
            <b-input
              id="inscricaoMunInput"
              v-model="value.inscricaoMunicipal"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Situação"
            label-for="situacaoSelect"
          >
            <b-select
              id="situacaoSelect"
              v-model="value.situacao"
              :options="[
                {text:'Nenhum', value: 'none'},
                {text: 'Ativa', value: 'ativa'},
                {text: 'Suspensa', value: 'suspensa'} ,
                {text:'Inapta', value: 'inapta'} ,
                {text:'Ativa não regular', value: 'ativanaoregular'} ,
                {text:'Baixada', value: 'baixada'}]"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Data situação"
            label-for="situacaoInput"
          >
            <data-input
              id="situacaoInput"
              v-model="value.dataSituacao"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Matriz/Filial"
            label-for="MatrizSelect"
          >
            <b-select
              id="MatrizSelect"
              v-model="value.isMatriz"
              :options="[
                {text:'Matriz', value: true},
                {text: 'Filial', value: false}]"
              @change="resetDadosMatriz()"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Empresa Matriz"
            label-for="cadastroFederalMatriz"
          >
            <matriz-select
              v-if="matrizNaBase"
              v-model="value.matriz"
              class="mb-50"
              :disabled="value.isMatriz"
              :raiz-cadastro-federal="value.cadastroFederal.substring(0, 10)"
              :id-empresa-atual="value.id"
            />
            <cadastro-federal-input
              v-else
              id="cadastroFederalMatriz"
              v-model="value.cnpjMatriz"
              class="mb-50"
              :is-required="false"
              label-title=""
              :is-to-buscar-dados="false"
              :disabled="value.isMatriz"
            />
            <b-form-checkbox
              v-model="matrizNaBase"
              :disabled="value.isMatriz"
              type="checkbox"
              class="mt-0 ml-80"
              @change="resetDadosMatriz()"
            >Cadastrado no sistema</b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label="Data de cadastro"
            label-for="cadastroInput"
          >
            <data-input
              id="criadoEmInput"
              v-model="value.criadoEm"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group
            label="Data última alteração"
            label-for="alteracaoInput"
          >
            <data-input
              id="alteradoEmInput"
              v-model="value.alteradoEm"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-row>
            <b-col cols="10">
              <b-form-group
                label="Data inativação"
                label-for="inativacaoInput"
              >
                <feather-icon
                  v-if="value.historicoInativacoes !== undefined && value.historicoInativacoes[0] && value.historicoInativacoes[0].situacao"
                  v-b-tooltip.hover
                  icon="InfoIcon"
                  style="position: absolute; top: 0; left: 115px;"
                  :class="'text-warning'"
                  :title="`${value.historicoInativacoes[0].situacao} ${value.historicoInativacoes[0].competencia} ${value.historicoInativacoes[1] ? `${value.historicoInativacoes[1].situacao} ${value.historicoInativacoes[1].competencia}` : ''}`"
                />
                <data-input
                  id="dataInativaInput"
                  v-model="value.dataInativa"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-btn
              v-b-tooltip.hover
              variant="none"
              title="Histórico de inativação"
              class="btn btn-sm btn-none px-50 py-0"
              @click="openModalHistoricoInativacao()"
            >
              <font-awesome-icon
                icon="fa-solid fa-list"
                class="text-primary"
              />
            </b-btn>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <historico-inativacao
      :ref="`modalHistoricoInativacao`"
      title="Histórico Inativação"
      :empresa="value"
    />
  </b-row>
</template>

<script>

import {
  telefone, celular,
} from '@pilar/mixins/mascaras'

import { ValidationProvider } from 'vee-validate'
import api from '@/app/empresas/shared/services/api'

export default {
  name: 'SecaoGeral',
  components: {
    ValidationProvider,
    CadastroFederalInput: () => import('@pilar/components/cadastro-federal-input/CadastroFederalInput.vue'),
    DivisoriaDescricao: () => import('@pilar/components/divisoria-descricao/DivisoriaDescricao.vue'),
    CertificadoSelect: () => import('@/app/shared/components/certificado-select/CertificadoSelect.vue'),
    MatrizSelect: () => import('@/app/shared/components/matriz-select/MatrizSelect.vue'),
    DataInput: () => import('@pilar/components/data-input/DataInput.vue'),
    HistoricoInativacao: () => import('../empresas/HistoricoInativacao.vue'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      telefone,
      celular,
      isentoEstadual: false,
      matrizNaBase: true,
    }
  },
  watch: {
    value(value, oldValue) {
      if (value !== oldValue) {
        this.isentoEstadual = value.inscricaoEstadual === 'ISENTO'
        this.matrizNaBase = value.matriz.id > 0
      }
    },
  },
  methods: {
    obterDados(cnpjInput) {
      return api.getEmpresaCadastroFederal(cnpjInput.replace('/', '%2F'), this.value)
    },
    cnpjExistente() {
      if (this.value.cadastroFederal) {
        api
          .existeCadastroFederal(this.value.id, this.value.cadastroFederal)
          .then(payload => {
            if (payload.data) {
              this.value.existeCnpj = true
              this.$refs.cadastroFederal.addErroValidacao('CNPJ já cadastrado')
              // {
              //   errors: ['CNPJ já cadastrado'], // array of string errors
              //   valid: false, // boolean state
              //   failedRules: {}, // should be empty since this is a manual error.
              // })
            } else {
              this.value.existeCnpj = false
            }
          })
      }
    },
    enviarDadosPaiCnpj(newDados) {
      const dados = newDados
      dados.id = this.value.id
      this.$emit('buscar-empresa', dados)
    },
    resetDadosMatriz() {
      this.value.matriz.id = 0
      this.value.matriz.cadastroFederal = ''
      this.value.matriz.razaoSocial = ''
      this.value.matriz.fimVigencia = ''
      this.value.cnpjMatriz = ''
    },
    openModalHistoricoInativacao() {
      this.$refs.modalHistoricoInativacao.show()
    },
  },
}
</script>
